<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        考生成绩/考生照片
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template table1"
        :rowKey="(item,index) => index"
        :columns="columns"
        :loading="loading"
        :data-source="tableData1"
        :pagination="false" 
        >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (queryParams.current - 1) * 20 + i + 1 }}
          </div>
        </template>
        <template slot="image" slot-scope="text">
          <viewer> 
            <img title="点击可放大预览" class="pictrue" :src="text" alt="">
          </viewer>
        </template>
      </a-table>

      <a-table
        v-if="isRight"
        class="table-template table2"
        :rowKey="(item,index) => index"
        :columns="columns"
        :loading="loading"
        :data-source="tableData2"
        :pagination="false" 
        >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (queryParams.current - 1) * 20 + i + 11 }}
          </div>
        </template>
        <template slot="image" slot-scope="text">
          <viewer> 
            <img title="点击可放大预览" class="pictrue" :src="text" alt="">
          </viewer>
        </template>
      </a-table>
    </div>
    <div class="foot">
      <a-button class="all_boder_btn" @click="goBack()">返回</a-button>
      <a-pagination 
        @change="onPage"
       :default-current="1" 
       :defaultPageSize="queryParams.size"
       :total="total" />
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "现场照片",
    align:'center',
    dataIndex: "picture",
    scopedSlots: { customRender: "image" }

  },
  {
    title: "时间",
    align:'center',
    dataIndex: "createTime"
  }
];
import HeaderBox from '@/components/HeaderBox.vue'

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns, // 表头
      tableData1:[], // 列表数据
      tableData2:[], // 列表数据
      isRight:false,
      loading:false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数

      queryParams:{
        examineeId: 0,  //tabID,
        current: 1, // 页码
        size: 20, // 页数
      },
    }
  },
  // 事件处理器
  methods: {
    // 返回上一页
    goBack(){
      this.$router.back()
    },
    // 分页
    onPage(e){
      this.queryParams.current = e
      this.getManageList()
    },
    // 查询列表
    getManageList(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/exam/examinee/picture/list",
        params: this.queryParams
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData1 = res.data.records.slice(0,10)// 获取前10条
          this.tableData2 = res.data.records.slice(10,20) // 获取后10条
          if(this.tableData2.length){ // 如果右边无数据，不展示
            this.isRight = true
          } else {
            this.isRight = false
          }
          this.total = res.data.total
        } else {
          this.tableData1 = []
          this.tableData2 = []
          this.total = 0
          this.$message.error(res.message);
        }
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.queryParams.examineeId = this.$route.query.examineeId
  },
  // 生命周期-实例挂载后调用
  mounted () { 
    this.getManageList()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn{
  margin-right: 24px;
}
.table-box{
  display: flex;
  justify-content: space-between;
  min-width: 800px;
  .table1{
    flex: 1;
  }
  .table2{
    margin-left: 30px;
    width: 49%;
  }
  /deep/ .ant-tabs-bar{
    border-bottom:none
  }
}
.foot{
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
/deep/ .ant-modal-footer{
  text-align: center;
  padding-bottom: 30px;
}
.pictrue{
  cursor: pointer;
  width: 90px;
}
</style>
